.app-style{
  width: 1560px;
}
.add-style{
  padding: 16px;
  margin: 16px;
}
.sort-button-area-style{
  padding: 16px;
  margin: 16px;
}
.filter-buton-area-style{
  padding: 16px;
  margin: 16px;
}
.add-button-style{
  padding: 16px;
  border-radius: 12px;
}
.add-input-style{
  padding: 16px;
  margin-right: 16px;
  border-radius: 12px;
}
.add-input-detail-style{
  width:  600px;
  padding: 16px;
  margin-right: 16px;
  border-radius: 12px;
}
.task-id-style {
  padding-left: 12px;
  padding-right: 24px;
}
.task-title-style {
  min-width: 160px;
  padding-right: 24px;
}
.task-status-style {
  padding: 16px;
  margin-right: 16px;
  border-radius: 12px;
}
.task-detail-style {
  min-width: 300px;
  max-width: 600px;
}
.task-date-style {
  min-width: 160px;
  padding-right: 24px;
}
.task-created-at-style {
  min-width: 160px;
  padding-right: 24px;
}
.task-delete-button-style {
  padding: 16px;
  border-radius: 12px;
}
.task-comment-button-style {
  padding: 16px;
  border-radius: 12px;
}
.task-edit-button-style {
  padding: 16px;
  border-radius: 12px;
}
.sort-button-style {
  padding: 16px;
  border-radius: 12px;
  margin-right: 16px;
}
.status-complete-task-style {
  display: flex;
  margin-bottom: 24px;
  border-radius: 12px;
  background-color: darkgray;
}
.status-in-progress-task-style {
  display: flex;
  margin-bottom: 24px;
  border-radius: 12px;
  background-color: lightgreen;
}
.status-not-started-yet-task-style {
  display: flex;
  margin-bottom: 24px;
  border-radius: 12px;
  background-color: white;
}
.filter-button-style {
  padding: 16px;
  border-radius: 12px;
  margin-right: 16px;
}
.react-datepicker-wrapper{
  width: unset !important;
}
.datepiker-wrap{
  width: 160px;
  display: inline-block;
  font-size: 12px;
}
.datepiker-input-style{
  padding: 6px;
  margin-right: 16px;
  border-radius: 12px;
}